import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol, IonContent, IonGrid, IonPage, IonRow, IonText,
    useIonRouter
} from '@ionic/react';

import { useContext, useEffect, useState } from 'react';
import { CardsStatus, IsCardsAvailable as IsRyftAvailable, IsCardsDisabled as IsRyftDisabled, Merchant } from '../lib';
import { AccountContext } from './controls/nav/AccountProvider';
import './Home.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useLocation} from 'react-router';
import { RowWithSmallGutters } from './controls/shared/Grid';
import TakePayment from './payments/TakePayment';
import React from 'react';

<style>
    {`
          button {
            background-color: blue;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
        `}
</style>
type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function Home(props: Props) {
    const accountContext = useContext(AccountContext);
    const [isCardPaymentsRowVisible, setIsCardPaymentsRowVisible] = useState(false);
    const [isCardPaymentsOnboardVisible, setIsCardPaymentsOnboardVisible] = useState(false);
    const location = useLocation();
    const router = useIonRouter();

    useEffect(() => {
        let cardDateString = localStorage.getItem('cardPaymentsRowHiddenUntil');
        if (!cardDateString) {
            setIsCardPaymentsRowVisible(true);
            return;
        }
        let cardMessageVisible = new Date() > new Date(cardDateString);
        setIsCardPaymentsRowVisible(cardMessageVisible);
    }, [location.key]);

    useEffect(() => {
        let cardDateString = localStorage.getItem('cardPaymentsOnboardHiddenUntil');
        if (!cardDateString) {
            setIsCardPaymentsOnboardVisible(true);
            return;
        }
        let cardMessageVisible = new Date() > new Date(cardDateString);
        setIsCardPaymentsOnboardVisible(cardMessageVisible);
    }, [location.key]);

    function handleNoClick() {
        let visibleUntilDate = new Date();
        visibleUntilDate.setDate(visibleUntilDate.getDate() + 14);
        localStorage.setItem('cardPaymentsRowHiddenUntil', visibleUntilDate.toISOString());
        setIsCardPaymentsRowVisible(false);
    };

    function handleLaterClick() {
        let visibleUntilDate = new Date();
        visibleUntilDate.setDate(visibleUntilDate.getDate() + 1);
        localStorage.setItem('cardPaymentsOnboardHiddenUntil', visibleUntilDate.toISOString());
        setIsCardPaymentsOnboardVisible(false);
    };

    function handleReauthoriseAccessTokenClick() {
        router.push("/reauthoriseMonitoring");
    }

    const rowStyles = {
        justifyContent: 'center',
        position: 'sticky',
        bottom: 5,
        backgroundColor: 'white',
        zIndex: 1, // Set a higher z-index value to ensure it appears above the toolbar
    };

    const rowStyleWithMargin = {
        ...rowStyles,

    };

    const notApplied = !accountContext?.merchant?.paymentMethods?.ryft ||
        accountContext?.merchant?.paymentMethods?.ryft?.status === CardsStatus.NotRequested;
    const setupInProgress =
        accountContext?.merchant?.paymentMethods?.ryft?.status === CardsStatus.VerificationRequired ||
        accountContext?.merchant?.paymentMethods?.ryft?.status === CardsStatus.VerificationSubmitted;

    function buildOtherWaysToPaySnippet(displayText: string, url: string) {
        return <IonButton
            style={{minimumWidth: "auto", padding: "0 8px", margin: "0 -5px"}}
            routerLink={url}>
            {displayText} &#187;
        </IonButton>;
    }

    function buildOtherWaysToPayPanel(showStandingOrders: boolean) {
        return <>
            <RowWithSmallGutters colClassName='ion-text-center'>
                <IonText style={{ fontSize: '18px', color: '#003466', fontWeight: 'bold' }}> Other Ways To Take Payments</IonText>

                <div className='ion-margin-top' style={{ display: "flex", justifyContent: "center", width:"auto"}}>
                    {buildOtherWaysToPaySnippet('Preset Payments', '/tabs/paymentpresets')}
                    {showStandingOrders && buildOtherWaysToPaySnippet('Standing Orders', '/tabs/standingorders')}
                </div>
            </RowWithSmallGutters>
        </>;
    }

    const standingOrdersDisconnectedMessage = <>
        <IonRow style={{
            justifyContent: 'center',
            backgroundColor: 'white',
            zIndex: 10,
            marginTop: '20px',
        }}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '20px' }} className='textRow'>Disconnected</IonText>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '500' }} className='textRow'>Click to reconnect</IonText>
                        </div>
                        <IonRow style={{ justifyContent: 'center', marginTop: '5px' }}>
                            <IonButton color={'secondary'} style={{ maxWidth: '200px' }} onClick={handleReauthoriseAccessTokenClick}>Reconnect Now &#187;</IonButton>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>

    </>

    const standingOrdersDisconnectionCloseMessage = <>
        <IonRow style={{
            justifyContent: 'center',

            backgroundColor: 'white',
            zIndex: 10,
            marginTop: '20px',
        }}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '700' }}>
                                Your token is going to disconnect in {accountContext?.merchant?.paymentMethods.tokenIo.daysUntilAccessTokenExpiry} days
                            </IonText>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '500' }} className='textRow'>Click to reconnect</IonText>
                        </div>
                        <IonRow style={{ justifyContent: 'center', marginTop: '5px' }}>
                            <IonButton color={'secondary'} style={{ maxWidth: '200px' }} onClick={handleReauthoriseAccessTokenClick}>Reconnect &#187;</IonButton>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    </>

    const activateCardsMessage = <>
        <IonRow style={rowStyleWithMargin}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px' }} className='textRow'>Do you want to enable card payments?</IonText>
                        </div>
                        <IonRow style={{ justifyContent: 'center' }}>
                            <IonCol sizeXs='6'>
                                <IonButton
                                    shape="round"
                                    color="primary"
                                    className='cardQuestionButton'
                                    onClick={handleNoClick}>
                                    No &#187;
                                </IonButton>
                            </IonCol>
                            <IonCol sizeXs='6'>
                                <IonButton
                                    shape="round"
                                    color="secondary"
                                    className='cardQuestionButton'
                                    onClick={() => router.push('/addCards')}>
                                    Yes &#187;
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    </>;

    const cardsDisabledMessage = <>
        <IonRow style={rowStyleWithMargin}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px' }} className='textRow'>Attention Needed</IonText>
                        </div>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '14px', fontWeight: 400 }} className='textRow'>We may need further input from you to complete the card payments setup process.</IonText>
                            </div>
                        </div>
                        <IonRow style={{ justifyContent: 'center' }}>
                            <IonCol sizeXs='6'>
                                <IonButton
                                    shape="round"
                                    color="primary"
                                    className='cardQuestionButton'
                                    onClick={handleLaterClick}>
                                    I’ll do it later &#187;
                                </IonButton>
                            </IonCol>
                            <IonCol sizeXs='6'>
                                <IonButton
                                    shape="round"
                                    color="secondary"
                                    className='cardQuestionButton'
                                    onClick={() => router.push('/addCards')}>
                                    Continue &#187;
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    </>;

    function selectMessagesToDisplay(): React.ReactNode[] {
        var snippets: React.ReactNode[] = [];

        if (!accountContext?.merchant?.settings) return snippets;

        if (accountContext.merchant?.paymentMethods?.tokenIo?.enabled &&
            accountContext.merchant.paymentMethods.tokenIo.standingOrdersSupported === true &&
            accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry !== undefined) {

            if (accountContext.merchant.paymentMethods.tokenIo.standingOrdersMonitored &&
                accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry < 1) {
                return [standingOrdersDisconnectedMessage];
            }

            if (accountContext.merchant.paymentMethods.tokenIo.standingOrdersMonitored &&
                accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry < 8) {
                return [standingOrdersDisconnectionCloseMessage];
            }

            snippets.push(buildOtherWaysToPayPanel(true));
        }
        else {
            snippets.push(buildOtherWaysToPayPanel(false));
        }

        if (accountContext.merchant.paymentMethods?.ryft?.status !== CardsStatus.Suppressed &&
            !IsRyftAvailable(accountContext.merchant) &&
            !IsRyftDisabled(accountContext.merchant) &&
            (accountContext.account?.merchantType != "submerchant") &&
            notApplied && isCardPaymentsRowVisible) {
            snippets.push(activateCardsMessage);
        }

        if (accountContext.merchant.paymentMethods?.ryft?.status !== CardsStatus.Suppressed &&
            IsRyftDisabled(accountContext.merchant) &&
            (accountContext.account?.merchantType != "submerchant") &&
            isCardPaymentsOnboardVisible && setupInProgress) {
            snippets.push(cardsDisabledMessage);
        }

        return snippets;
    }

    return <>
        <IonPage>
            <IonContent className='takePaymentContent'>
                <IonGrid style={{ height: '100%' }}>
                    {TakePayment()}

                    {selectMessagesToDisplay().map((snippet, index) => (
                        <React.Fragment key={index}>
                            {snippet}
                        </React.Fragment>
                    ))}
                </IonGrid>
            </IonContent>
        </IonPage>
    </>;
}
