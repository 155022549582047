import { IonButton, IonContent, IonGrid, IonPage, IonText, IonToolbar, useIonRouter } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppConfig, CardsStatus, GetConfig, Merchant } from '../lib';
import { AccountContext } from '../pages/controls/nav/AccountProvider';
import { RowWithDynamicGutter, RowWithSmallGutters } from './controls/shared/Grid';
import './Settings.css';
import CardPayments from './settings/CardPayments';
import LogoUpload from './settings/LogoUpload';
import Logout from './settings/Logout';
import RedirectUrl from './settings/RedirectUrl';
import ClearMerchant from './settings/ResetAccount';
import VatNumber from './settings/VatNumber';
import DownloadDataControl from './settings/DownloadData';
import PageSpinner from './controls/shared/PageSpinner';

const Settings = () => {
    const [merchant, setMerchant] = useState<Merchant | undefined | null>();
    const config = useRef<AppConfig>();
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    async function refreshMerchant() {
        setMerchant(null);
        accountContext?.refresh();
    }

    useEffect(() => {
        setMerchant(accountContext?.merchant);
    }, [accountContext?.merchant])

    useEffect(() => { GetConfig().then(c => config.current = c); }, []);

    return <>{merchant ?
        <>
            <IonPage>
                <IonToolbar className='titleBar'>
                    <IonText>Settings</IonText>
                </IonToolbar>
                <IonContent>
                    <IonGrid>
                        {accountContext?.account?.merchantType === "merchant"
                            && <>
                                <RowWithSmallGutters colClassName='greyPanel'>
                                    <RedirectUrl merchant={merchant!} refreshMerchant={refreshMerchant} />
                                </RowWithSmallGutters>

                                <RowWithSmallGutters colClassName='greyPanel'>
                                    <LogoUpload merchant={merchant!} refreshMerchant={refreshMerchant} />
                                </RowWithSmallGutters>

                                {merchant.paymentMethods?.ryft?.status !== CardsStatus.Suppressed &&
                                    <RowWithSmallGutters colClassName='greyPanel'>
                                        <CardPayments merchant={merchant!} refreshMerchant={refreshMerchant} />
                                    </RowWithSmallGutters>}

                                <RowWithSmallGutters colClassName='greyPanel'>
                                    <VatNumber merchant={merchant!} refreshMerchant={refreshMerchant} />
                                </RowWithSmallGutters>

                                <RowWithSmallGutters colClassName='greyPanel'>
                                    <DownloadDataControl />
                                </RowWithSmallGutters>
                            </>
                        }

                        <RowWithSmallGutters colClassName='greyPanel'>
                            <div className='panelTitle'>
                                Help
                            </div>
                            <p>
                                <a className='contactSupport ion-text-left' onClick={() => router.push("/tabs/ContactSupport")}>
                                    <u>Contact Support</u>
                                </a>
                            </p>
                        </RowWithSmallGutters>

                        <RowWithSmallGutters colClassName='greyPanel'>
                            <div className='panelTitle' style={{ color: '#003466' }}>
                                About
                            </div>
                            <p>
                                <a href="https://trustisttransfer.com/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Service</a>
                            </p>
                            <p>
                                <a href="https://trustisttransfer.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                            </p>
                        </RowWithSmallGutters>

                        <RowWithDynamicGutter>
                            <Logout className='logOutButton' />
                        </RowWithDynamicGutter>

                        {(config.current?.isDevelopment === true || config.current?.isStaging === true) && <RowWithDynamicGutter><ClearMerchant /></RowWithDynamicGutter>}

                        <RowWithSmallGutters>
                            <div className='cancelAccountButton' style={{ color: '#003466' }}>
                                <IonButton fill='clear' onClick={() => router.push("/tabs/CancelAccount")}><u>Cancel Account</u></IonButton>
                            </div>
                        </RowWithSmallGutters>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </>
        :
        <PageSpinner />
    }</>;
}

export default Settings;
